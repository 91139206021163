import { useEffect, useState } from "react";

// import Navbar from "../layout/navbar";
// import Footer from "../layout/footer";

// import isEmpty from "../utils/isEmpty";

// import { useNavigate } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import { connect } from "react-redux";
import { getOptions, getAvailableDates, startConstruction, resetTrades, createDeribitCombo } from "../store/deribit/actions";
import {
	AppBar,
	Box,
	Button,
	Card,
	CardContent,
	Checkbox,
	Collapse,
	// CircularProgress,
	Container, 
	FormControl, 
	FormControlLabel, 
	FormGroup, 
	FormLabel, 
	Grid, 
	IconButton, 
	// IconButton, 
	InputLabel, 
	LinearProgress, 
	List, 
	ListItem, 
	ListItemAvatar, 
	ListItemText, 
	MenuItem, 
	Paper, 
	Select, 
	TextField, 
	// SelectChangeEvent, 
	Toolbar,
	Typography
} from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
	DataGrid,
	// GridColDef,
	GridValueGetterParams
} from '@mui/x-data-grid';

import axios from "axios";
import { apiUrl, live, wsUri } from "../config";

import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from "@mui/x-date-pickers";

dayjs.extend(utc);
dayjs.extend(timezone);

const Home = (props) => {
	// const router = useNavigate();
	const currencies = ['BTC', 'ETH'];
	const [selectedCurrency, setSelectedCurrency] = useState('BTC');
	const [btcRv, setBtcRv] = useState(0);

	const orderTypes = ['call', 'put'];
	const [selectedOrderType, setSelectedOrderType] = useState('call');

	const orderSides = ['long', 'short'];
	const [selectedOrderSide, setSelectedOrderSide] = useState('long');

	const [currentPrice, setCurrentPrice] = useState(0);

	// User Form values
	const [userCommission, setUserCommission] = useState(0.001);
	const [userSettlementCommission, setUserSettlementCommission] = useState(0.002);
	const [userCapitalLimit, setUserCapitalLimit] = useState(1);
	
	const [atmMaxCost, setAtmMaxCost] = useState(1);
	const [atmMinValue, setAtmMinValue] = useState(1);
	const [atPriceMaxCost, setAtPriceMaxCost] = useState(1);
	const [atPriceMinValue, setAtPriceMinValue] = useState(1);
	
	const [rVolatility , setRVolatility] = useState(0);

	// const [endTimeLimit, setEndTimeLimit] = useState<Dayjs | null>(dayjs('2024-01-30'));
	const [endTimeLimit, setEndTimeLimit] = useState(null);

	const [userShowCommission, setUserShowCommission] = useState(0.001);
	const [userSettlementShowCommission, setUserSettlementShowCommission] = useState(0.002);

	const [selectedAvailableDate, setSelectedAvailableDate] = useState([]);

	const [wsInstance, setWsInstance] = useState(null);

	const [marketData, setMarketData] = useState([]);
	const [marketDataProcess, setMarketDataProcess] = useState(false);
	const [exportMarketData, setExportMarketData] = useState([
		['NAME', 'SIZE', 'IV (BID)', 'Bid', 'Mark', 'ASK', 
		'IV (ASK)', 'Open', 'Delta', 'Position'],
	]);

	const [butterflyData, setButterfly] = useState([]);
	const [butterflyProcess, setButterflyProcess] = useState(false);
	const [exportButterflyData, setExportButterflyData] = useState([
		['MARKET', 'EXPIRY', 'LOW STRIKE', 'MIDDLE STRIKE', 'HIGH STRIKE', 'DAYS EXPIRY', 
		// 'AVERAGE VOLATILITY', 
		'PRICE OF BUTTERFLY', 'TRADE VALUE', 'NORMAL COMMISSION',
		'EXPIRY COMMISSION', 'PROJECTED PL']
	]);

	const [userTrades, setUserTrades] = useState([]);
	const [exportUserTradeData, setExportUserTradeData] = useState([
		[
			'Trade Noted', 
			'Trade Size', 
			'ASK at time of trade notation',
			'Cost (Current BID)', 
			'Expected Value (%)',
			'Buy', 
			'Sell (2)', 
			'Buy',
			'Underlying Market Price at time of trade discovery',
			'Current Profit',
			'APY (%)',
			`${selectedCurrency} Gain`,
			'Dollar Gain',
			'Yeild APY (%)',
			'Top 24h Gain'
		],
	]);
	const [exportUserLongTradeData, setExportUserLongTradeData] = useState([
		[
			'Trade Noted', 
			'Trade Size', 
			'ASK at time of trade notation',
			'Cost (Current BID)', 
			'Expected Value (%)',
			'Buy', 
			'Sell (2)', 
			'Buy',
			'Underlying Market Price at time of trade discovery',
			'Current Profit',
			'APY (%)',
			`${selectedCurrency} Gain`,
			'Dollar Gain',
			'Yeild APY (%)',
			'Top 24h Gain'
		],
	]);

	const [lastBookDownloaded, setLastBookDownloaded] = useState("");
	const [lastOrderBookTime, setLastOrderBookTime] = useState("");
	const [schedulerReqData, setSchedulerReqData] = useState([]);

	const [autoEnterMinimumExpectedYeild, setAutoEnterMinimumExpectedYeild] = useState(false);
	const [autoExitMinimumProfit, setAutoExitMinimumProfit] = useState(false);
	const [autoExitMinimumYeild, setAutoExitMinimumYeild] = useState(false);
	const [autoExitMinimumGainCaptured, setAutoExitMinimumGainCaptured] = useState(false);

	const isBrowser = typeof window !== "undefined";
	
	useEffect(() => {
		props.getAvailableDates(selectedCurrency, 'option', selectedOrderType, selectedOrderSide);
	}, []);

	useEffect(() => {
		if (isBrowser) {
			if (wsInstance) {
				if (wsInstance.readyState !== WebSocket.CLOSED) {
					const ws = new WebSocket(`${wsUri}`);
					setWsInstance(ws);
					return () => {
						// Cleanup on unmount if ws wasn't closed already
						if (ws?.readyState !== 3) {
							ws.close();
						}
					};
				}
			} else {
				const ws = new WebSocket(`${wsUri}`);
					setWsInstance(ws);
					return () => {
						// Cleanup on unmount if ws wasn't closed already
						if (ws?.readyState !== 3) {
							ws.close();
						}
					};
			}
		}
		return () => {
			if (wsInstance) {
				if (wsInstance.readyState !== WebSocket.OPEN) {
					wsInstance.close();
				}
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const processWsData = async (data) => {
		const marketData = JSON.parse(data);
		if (marketData.event === 'stream') {
			setBtcRv(marketData.btcRv);
			setMarketDataProcess(marketData.process);
			setMarketData(marketData.marketData);
			setCurrentPrice(marketData.currentPrice);

			if (parseFloat(marketData.rVolatility) !== 0) {
				if (marketData.atmMaxCost && marketData.atmMinValue && marketData.atPriceMaxCost && marketData.atPriceMinValue) {
					setAtmMaxCost(marketData.atmMaxCost);
					setAtmMinValue(marketData.atmMinValue);
					setAtPriceMaxCost(marketData.atPriceMaxCost);
					setAtPriceMinValue(marketData.atPriceMinValue);
				}
	
				if (marketData.rVolatility) {
					setRVolatility(marketData.rVolatility);
				}
			}


			let exExMarketData = [
				['NAME', 'BID SIZE', 'IV (BID)', 'Bid', 'Mark', 'ASK', 
				'IV (ASK)', 'ASK SIZE', 'Open', 'Delta', 'Position', 'Market Price'],
			];

			for (let exMarketData of marketData.lastMarketData) {
				exExMarketData.push([
					exMarketData.instrument_name,
					exMarketData.best_bid_amount,
					exMarketData.bid_iv,
					exMarketData.best_bid_price,
					exMarketData.mark_price,
					exMarketData.best_ask_price,
					exMarketData.ask_iv,
					exMarketData.best_ask_amount,
					exMarketData.open_interest,
					exMarketData.greeks.delta,
					'-',
					// exMarketData.stats.volume,
					currentPrice,
				]);
			}

			setExportMarketData(exExMarketData);
		}

		if (marketData.event === 'extrades') {
			setUserTrades(marketData.exTrades);
			setLastBookDownloaded(marketData.lastBookDownloaded);
			setLastOrderBookTime(marketData.lastOrderBookTime);
			setSchedulerReqData(marketData.schedulerReqData);
			setUserShowCommission(marketData.userShowCommission);
			setUserSettlementShowCommission(marketData.userSettlementShowCommission);

			let exUserLongTradesData = [
				[
					'Trade Noted', 
					'Trade Size', 
					'ASK at time of trade notation',
					'Cost (Current BID)', 
					'Expected Value (%)',
					'Buy', 
					'Sell (2)', 
					'Buy',
					'Underlying Market Price at time of trade discovery',
					'Current Profit',
					'APY (%)',
					`${selectedCurrency} Gain`,
					'Dollar Gain',
					'Yeild APY (%)',
					'Top 24h Gain'
				]
			];

			for (let exLongTrade of marketData.exTrades) {
				if (exLongTrade.longTradeToExecute.length > 0) {
					exUserLongTradesData.push([
						new Date(exLongTrade.notedDate).toLocaleString(),
						exLongTrade.longTradeData.tradeSize,
						exLongTrade.longTradeData.longPrice,
						exLongTrade.longTradeData.currentBidPrice,
						parseFloat(exLongTrade.longTradeData.expected)*100,
						exLongTrade.longTradeToExecute[0]?.security,
						exLongTrade.longTradeToExecute[1]?.security,
						exLongTrade.longTradeToExecute[2]?.security,
						exLongTrade.longTradeData.currentPrice,
						parseFloat(exLongTrade.longTradeData.tradeCost) - (parseFloat(exLongTrade.longTradeData.expected)*100),
						parseFloat(exLongTrade.userTradeProfit).toFixed(2),
						exLongTrade.longTradeData.expectedProfit,
						exLongTrade.longTradeData.receiveProfit.toFixed(2),
						parseFloat(exLongTrade.longTradeData.userAnnulisedTradeProfit).toFixed(2),
						exLongTrade.longTradeData.receiveProfit.toFixed(2)
					]);
				}
			}
			setExportUserLongTradeData(exUserLongTradesData);

			let exUserTradesData = [
				[
					'Trade Noted', 
					'Trade Size', 
					'ASK at time of trade notation',
					'Cost (Current BID)', 
					'Expected Value (%)',
					'Buy', 
					'Sell (2)', 
					'Buy',
					'Underlying Market Price at time of trade discovery',
					'Current Profit',
					'APY (%)',
					`${selectedCurrency} Gain`,
					'Dollar Gain',
					'Yeild APY (%)',
					'Top 24h Gain'
				]
			];

			
			for (let exTrade of marketData.exTrades) {
				setSelectedCurrency(exTrade.tradeToExecute[0].security.split("-")[0]);
				exUserTradesData.push([
					new Date(exTrade.notedDate).toLocaleString(),
					exTrade.tradeSize,
					exTrade.longPrice,
					exTrade.currentBidPrice,
					parseFloat(exTrade.expected)*100,
					exTrade.tradeToExecute[0].security,
					exTrade.tradeToExecute[1].security,
					exTrade.tradeToExecute[2].security,
					exTrade.currentPrice,
					parseFloat(exTrade.tradeCost) - (parseFloat(exTrade.expected)*100),
					parseFloat(exTrade.userTradeProfit).toFixed(2),
					exTrade.expectedProfit,
					exTrade.receiveProfit.toFixed(2),
					parseFloat(exTrade.userAnnulisedTradeProfit).toFixed(2),
					exTrade.receiveProfit.toFixed(2)
				]);
			}
			setExportUserTradeData(exUserTradesData);
		}

		if (marketData.event === 'butterfly') {
			setButterfly(marketData.butterflyData);
			setButterflyProcess(marketData.process);

			let exExButterflyData = [
				[
					'Long Low Strike Call', 'Short Middle Strike Calls', 
					'Long High Strike Calls',
					'Capital At Risk', 'Expiry', 
					'Low Strike', 'Middle Strike', 'High Strike', 
					'Days to Expiry', 
					'Average Volatility', 
					'%Cost of Butterfly', 'Projected Value of Butterfly on Expiration', 'Trade Type',
					'Worst Outcome', 
					// 'Max Short Loss', 
					'Guaranteed Yield', 'Trade Size', 'Value Of Option Bought',
					'Commission Underline', 'Commission Of Options', 'Long / Short Options Commission',
					'Expected', 'Annualised',
					'closing costs automatically', 'take comission', 'transaciton value of options',
					'transaciton value with commission',
					'taker comission on plus exit', 'the lesse of the comission',
					'expectedProfit', 'isUnderCapital',
				]
			];

			for (let exButterflyData of marketData.lastButterflyData) {
				exExButterflyData.push([
					exButterflyData.lowStrikeMarket,
					exButterflyData.middleStrikeMarket,
					exButterflyData.highStrikeMarket,
					exButterflyData.capitalAtRisk,
					exButterflyData.expiry,
					exButterflyData.lowStrike,
					exButterflyData.middleStrike,
					exButterflyData.highStrike,
					exButterflyData.dateOfExpiry,
					'',
					exButterflyData.priceOfButterfly,
					exButterflyData.tradeValue,
					exButterflyData.tradeType,
					exButterflyData.worstOutput,
					// exButterflyData.maxShortLoss,
					exButterflyData.guaranteedYield,
					exButterflyData.tradeSize,
					exButterflyData.valueOfOptionsBought,
					exButterflyData.commissionOfUnderline,
					exButterflyData.commissionOfOptions,
					exButterflyData.longOrShortCommission,
					exButterflyData.expected,
					exButterflyData.annualisedExpectedReturn,
					// new data
					exButterflyData.closingCostAuto,
                    exButterflyData.takerCommission,
                    exButterflyData.txValueOfOption,
                    exButterflyData.txValueOfOptionWithCommisstion,
                    exButterflyData.takerCommissionOnExit,
                    exButterflyData.lessOfCommissionForLong,
					exButterflyData.expectedProfit,
					exButterflyData.isUnderCapital,
				]);
			}
			setExportButterflyData(exExButterflyData);
		}
	};

	if (wsInstance) {
		wsInstance.onopen = function (evt) {
            // WS connected
		};

		wsInstance.onmessage = function (evt) {
			if (wsInstance.readyState === WebSocket.OPEN) {
				processWsData(evt.data);
			}
		};

		wsInstance.onerror = function (evt) {
			// console.log(evt);
		};
	}

	const startDownloading = () => {
		props.getOptions(
			selectedCurrency, 
			'option', 
			selectedOrderType, 
			selectedAvailableDate, 
			selectedOrderSide,
			userCommission,
			userSettlementCommission,
			userCapitalLimit,
			atmMaxCost,
			atmMinValue,
			atPriceMaxCost,
			atPriceMinValue,
			rVolatility,
			new Date(endTimeLimit).getTime(),
		);
	}

	const startResetTrades = async () => {
		await axios
			.get(`${apiUrl}/v1/reset_trades`)
			.then((response) => response)
			.catch((err) => err.response);

		console.log(
			'Starting construction'
		)
	}

	const handleChange = (event) => {
		setSelectedAvailableDate(event.target.value);
	};

	const handleCurrencyChange = (event) => {
		setSelectedCurrency(event.target.value);
		props.getAvailableDates(event.target.value, 'option', selectedOrderType);
	}

	const handleorderSideChange = (event) => {
		setSelectedOrderSide(event.target.value);
		props.getAvailableDates(selectedCurrency, 'option', selectedOrderType);
	}

	const handleorderTypesChange = (event) => {
		setSelectedOrderType(event.target.value);
		props.getAvailableDates(selectedCurrency, 'option', event.target.value);
	}

	const handleButterflyProcess = async (event) => {
		await axios
			.get(`${apiUrl}/v1/start_butterfly_contruction?live=${live}`)
			.then((response) => response)
			.catch((err) => err.response);

		console.log(
			'Starting construction'
		)
	}

	const createDeribitOrders = async (tradeToExecute) => {
		let trades = [];
		for (const deriTrade of tradeToExecute) {
			const maxAmountToSent = parseFloat(deriTrade.amount) > parseFloat(userCapitalLimit) ? userCapitalLimit : deriTrade.amount;
			trades.push({
				"instrument_name": deriTrade.security,
				// "amount": parseFloat(maxAmountToSent).toFixed(3),
				"amount": parseFloat(deriTrade.amount),
				"direction": deriTrade.side.toLowerCase(),
			})
		}
		console.log(trades);
		try {
			let tradeRes = await axios
				.post(`${apiUrl}/v1/create_butterfly_combo`, {
					trades: trades
				});
			alert(`${tradeRes.data.data}`);
		} catch(e) {
			alert(`${e.response.data.msg}`);
		}
	}

	const createLongDeribitOrders = async (
		longTradeToExecute
	) => {
		let longTrades = [];
		for (const deriTrade of longTradeToExecute) {
			const maxAmountToSent = parseFloat(deriTrade.amount) > parseFloat(userCapitalLimit) ? userCapitalLimit : deriTrade.amount;
			longTrades.push({
				"instrument_name": deriTrade.security,
				// "amount": parseFloat(maxAmountToSent).toFixed(3),
				"amount": parseFloat(deriTrade.amount),
				"direction": deriTrade.side.toLowerCase(),
			})
		}
		console.log(longTrades);
		try {
			let longTradeRes = await axios
				.post(`${apiUrl}/v1/create_butterfly_combo`, {
					trades: longTrades
				});
			alert(`${longTradeRes.data.data}`);
		} catch(e) {
			alert(`${e.response.data.msg}`);
		}
	}

	return (
		<div className="mainBody">
			<AppBar position="static">
				<Toolbar>
					<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
						Butterfly Trade
					</Typography>
				</Toolbar>
			</AppBar>
			<Container maxWidth="lg" style={{
				paddingTop: 10,
				paddingBottom: 50
			}}>
				<Grid
					container
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					spacing={5}
				>
					<Grid item md={8}>
						<FormControl fullWidth>
							<InputLabel id="day-select-label">Select Day</InputLabel>
							<Select
								disabled={marketDataProcess}
								labelId="day-select-label"
								id="day-select"
								value={selectedAvailableDate}
								multiple={true}
								label="Day"
								onChange={handleChange}
							>
								{
									props.deribit.availableDates.map((availableDate, index) => <MenuItem key={index} value={availableDate}>{availableDate}</MenuItem>)
								}
							</Select>
						</FormControl>
					</Grid>
					<Grid item md={4}>
						<span>UTC Time: {new Date().toUTCString()}</span>
					</Grid>
					<Grid item md={6}>
						<FormControl fullWidth>
							<InputLabel id="day-select-label">Selete Currency</InputLabel>
							<Select
								labelId="day-select-label"
								id="day-select"
								value={selectedCurrency}
								label="Day"
								onChange={handleCurrencyChange}
							>
								{
									currencies.map((currrency, index) => <MenuItem key={index} value={currrency}>{currrency}</MenuItem>)
								}
							</Select>
						</FormControl>
					</Grid>
					{/* <Grid item md={2}>
						<FormControl fullWidth>
							<InputLabel id="side-select-label">Selete Order Side</InputLabel>
							<Select
								labelId="side-select-label"
								id="side-select"
								value={selectedOrderSide}
								label="Order Side"
								onChange={handleorderSideChange}
							>
								{
									orderSides.map((orderSide, index) => <MenuItem key={index} value={orderSide}>{orderSide}</MenuItem>)
								}
							</Select>
						</FormControl>
					</Grid> */}
					{/* <Grid item md={2}>
						<FormControl fullWidth>
							<InputLabel id="day-select-label">Selete Order Type</InputLabel>
							<Select
								labelId="day-select-label"
								id="day-select"
								value={selectedOrderType}
								label="Day"
								onChange={handleorderTypesChange}
							>
								{
									orderTypes.map((orderType, index) => <MenuItem key={index} value={orderType}>{orderType}</MenuItem>)
								}
							</Select>
						</FormControl>
					</Grid> */}
					<Grid item md={3}>
						<Typography variant="h6">Underlying Asset Market Price: {currentPrice}</Typography>
					</Grid>
					<Grid item md={3}>
						{/* <Typography variant="h6">RV: {parseFloat(btcRv).toFixed(6)}%</Typography> */}

						<FormControl fullWidth>
							<TextField id="rVolatility" label="RV (%)" variant="outlined" value={rVolatility} onChange={(e) => {
								setRVolatility(e.target.value);
							}} />
						</FormControl>
					</Grid>
					{/* <Grid item md={3}>
						<FormControl fullWidth>
							<TextField id="user-commission" label="Commission" variant="outlined" value={userCommission} onChange={(e) => {
								setUserCommission(e.target.value);
							}} />
						</FormControl>
					</Grid>
					<Grid item md={3}>
						<FormControl fullWidth>
							<TextField id="settlement-commission" label="Settlement Commission" variant="outlined" value={userSettlementCommission} onChange={(e) => {
								setUserSettlementCommission(e.target.value);
							}} />
						</FormControl>
					</Grid> */}
					{/* <Grid item md={3}>
						<FormControl fullWidth>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={['DateTimePicker']}>
									<DateTimePicker
										label="End Time Limit" 
										defaultValue={dayjs.tz(`${new Date().toUTCString()}`, "GMT")} 
										onChange={(newValue) => {
											setEndTimeLimit(`${new Date(newValue)}`)
										}}
									/>
								</DemoContainer>
							</LocalizationProvider>
						</FormControl>
					</Grid> */}
					<Grid item md={3}>
						<FormControl fullWidth>
							<TextField id="capital-limit" label={`Total ${selectedCurrency} Allocated to all Trades limit`} variant="outlined" value={userCapitalLimit} onChange={(e) => {
								setUserCapitalLimit(e.target.value);
							}} />
						</FormControl>
					</Grid>
					<Grid item md={3}>
						<FormControl fullWidth>
							<TextField id="atmMaxCost" label={`ATM Price Limit (${selectedCurrency})`} variant="outlined" value={atmMaxCost} onChange={(e) => {
								setAtmMaxCost(e.target.value);
							}} />
						</FormControl>
					</Grid>
					<Grid item md={3}>
						<FormControl fullWidth>
							<TextField id="atmMinValue" label={`ATM Max Contract Value per Trade (${selectedCurrency})`} variant="outlined" value={atmMinValue} onChange={(e) => {
								setAtmMinValue(e.target.value);
							}} />
						</FormControl>
					</Grid>
					<Grid item md={3}>
						<FormControl fullWidth>
							<TextField id="atPriceMaxCost" label="Markup for auto sale (%)" variant="outlined" value={atPriceMaxCost} onChange={(e) => {
								setAtPriceMaxCost(e.target.value);
							}} />
						</FormControl>
					</Grid>
					{/* <Grid item md={3}>
						<FormControl fullWidth>
							<TextField id="atPriceMinValue" label="At Price Min Value (Contract)" variant="outlined" value={atPriceMinValue} onChange={(e) => {
								setAtPriceMinValue(e.target.value);
							}} />
						</FormControl>
					</Grid> */}
					<Grid item md={12}>
						<FormControl component="fieldset">
							<FormGroup aria-label="position" row>
								<FormControlLabel
									control={
										<Checkbox 
											checked={autoEnterMinimumExpectedYeild} 
											onChange={() => {
												setAutoEnterMinimumExpectedYeild(!autoEnterMinimumExpectedYeild);
											}}
										/>
									}
									label="Auto Enter Minimum Expected Yeild"
									labelPlacement="start"
								/>
								<FormControlLabel
									control={
										<Checkbox 
											checked={autoExitMinimumProfit} 
											onChange={() => {
												setAutoExitMinimumProfit(!autoExitMinimumProfit);
											}}
										/>
									}
									label="Auto Exit Minimum Profit"
									labelPlacement="start"
								/>
								<FormControlLabel
									control={
										<Checkbox 
											checked={autoExitMinimumYeild} 
											onChange={() => {
												setAutoExitMinimumYeild(!autoExitMinimumYeild);
											}}
										/>
									}
									label="Auto Exit Minimum Yeild"
									labelPlacement="start"
								/>
								<FormControlLabel
									control={
										<Checkbox 
											checked={autoExitMinimumGainCaptured} 
											onChange={() => {
												setAutoExitMinimumGainCaptured(!autoExitMinimumGainCaptured);
											}}
										/>
									}
									label="Auto Exit Minimum % of Gain Captured"
									labelPlacement="start"
								/>
							</FormGroup>
						</FormControl>
					</Grid>

					<Grid item md={1}>
							<Button variant="contained" disabled={marketData?.rVolatility ? parseFloat(marketData.rVolatility) !== 0 : false} onClick={startDownloading}>Start</Button>
					</Grid>
					<Grid item md={1}>
						<Button variant="contained" onClick={startResetTrades}>Reset</Button>
					</Grid>
				</Grid>

				<Grid
					container
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={5}
					style={{
						paddingTop: 10
					}}
				>
					<Grid item md={12} pt={10}>
						{(butterflyProcess || marketDataProcess) ? 
							<LinearProgress />
						 : 
						  <div></div>
						 }
						<DataGrid
							rowHeight={25}
							rows={marketData}
							getRowId={(row) => {
								return row.id;
							}}
							columns={[
								{ field: 'instrument_name', headerName: 'Name', width: 200 },
								{ field: 'best_bid_amount', headerName: 'Bid Size', width: 100 },
								{ field: 'bid_iv', headerName: 'IV (Bid)', width: 100 },
								{ field: 'best_bid_price', headerName: 'Bid', width: 100 },
								{ field: 'mark_price', headerName: 'Mark', width: 100 },
								{ field: 'best_ask_price', headerName: 'Ask', width: 100 },
								{ field: 'ask_iv', headerName: 'IV (Ask)', width: 100 },
								{ field: 'best_ask_amount', headerName: 'Ask Size', width: 100 },
								{ field: 'open_interest', headerName: 'Open', width: 100 },
								{
									field: 'greeks',
									headerName: 'Delta',
									width: 100,
									valueGetter: (params) => {
										return `${params.row.greeks.delta}`;
									},
								},
								{ field: '-', headerName: 'Position', width: 100 },
								{
									field: 'stats',
									headerName: 'Volume',
									width: 100,
									valueGetter: (params) => {
										return `${params.row.stats.volume}`;
									},
								},
							]}
							initialState={{
								pagination: {
									paginationModel: { page: 0, pageSize: 10 },
								},
							}}
							pageSizeOptions={[5, 10]}
						/>
					</Grid>
					{/* <Grid item md={12} pt={10}>
						{(butterflyProcess || marketDataProcess) ? 
							<LinearProgress />
						 : 
						  <div></div>
						 }
						 <table style={{width: '100%'}}>
							<thead>
								<tr>
									<th>Name</th>
									<th>Size</th>
									<th>IV (Bid)</th>
									<th>Bid</th>
									<th>Mark</th>
									<th>Ask</th>
									<th>IV (Ask)</th>
									<th>Open</th>
									<th>Delta</th>
									<th>Position</th>
									<th>Volume</th>
								</tr>
							</thead>
							<tbody>
								{
									marketData.map((mdata, index) => 
										<tr key={index}>
											<td>{mdata.instrument_name}</td>
											<td>{mdata.best_bid_amount}</td>
											<td>{mdata.bid_iv}</td>
											<td>{mdata.best_bid_price}</td>
											<td>{mdata.mark_price}</td>
											<td>{mdata.best_ask_price}</td>
											<td>{mdata.ask_iv}%</td>
											<td>{mdata.open_interest}</td>
											<td>{mdata.greeks.delta}</td>
											<td>-</td>
											<td>{mdata.stats.volume}</td>
										</tr>
									)
								}
							</tbody>
						</table>
					</Grid> */}
				</Grid>
					<Card variant="outlined" style={{width: '100%', paddingTop: 10}}>
						<CardContent>		
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								spacing={5}
							>
								{/* <Grid item md={6}>
									<Typography variant="h6" gutterBottom>
										Construct Butterfly
									</Typography>
								</Grid> */}
								<Grid item md={2}>
									<Button 
										variant="contained" 
										disabled={(butterflyProcess) || (marketDataProcess) || ((marketData.length <= 0) && !marketDataProcess)}
										onClick={handleButterflyProcess}
									>Construct</Button>
								</Grid>
								<Grid item md={2}>
									<CSVLink data={exportMarketData} filename="MarketData.csv">
										<Button>Export MarketData</Button>
									</CSVLink>
								</Grid>
								<Grid item md={2}>
									<CSVLink data={exportButterflyData} filename="Butterfly_Construct.csv">
										<Button>Export Butterfly</Button>
									</CSVLink>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={5}
					>
						{/* <Grid item md={12} pt={10}>
							{(butterflyProcess || marketDataProcess) ? 
								<LinearProgress />
							: 
							<div></div>
							}
							<DataGrid
								rowHeight={25}
								rows={butterflyData}
								getRowId={(row) => {
									return row.id;
								}}
								columns={[
									// { field: 'lowStrikeMarket', headerName: 'Market', width: 200 },
									// {
									// 	field: 'lowStrikeMarket',
									// 	headerName: 'Market',
									// 	width: 130,
									// 	valueGetter: (params) => {
									// 		return `${params.row.lowStrikeMarket.split('-')[0]} ${params.row.lowStrikeMarket.split('-')[1]}`;
									// 	},
									// },
									{ field: 'lowStrikeMarket', headerName: 'Low Strike Market', width: 120 },
									{ field: 'middleStrikeMarket', headerName: 'Middle Strike Market', width: 120 },
									{ field: 'highStrikeMarket', headerName: 'High Strike Market', width: 120 },
									{ field: 'expiry', headerName: 'Expiry', width: 120 },
									{ field: 'lowStrike', headerName: 'Low Strike', width: 120 },
									{ field: 'middleStrike', headerName: 'Middle Strike', width: 120 },
									{ field: 'highStrike', headerName: 'High Strike', width: 120 },
									{ field: 'dateOfExpiry', headerName: 'Days Expiry', width: 120 },
									// { field: 'averageVolatility', headerName: 'Average Volatility', width: 120 },
									{ field: 'priceOfButterfly', headerName: '% Cash Flow of Butterfly', width: 120 },
									{ field: 'tradeValue', headerName: 'Projected Value of Butterfly on Expiration', width: 120 },
									{ field: 'tradeType', headerName: 'Trade Type', width: 120 },
									{ field: 'worstOutput', headerName: 'Worst Output', width: 120 },
									{ field: 'annualisedExpectedReturn', headerName: 'Annulised Expected Return', width: 120 },
									{ field: 'maxShortLoss', headerName: 'Max Short Loss', width: 120 },
									{ field: 'guaranteedYield', headerName: 'Guaranteed Yield', width: 120 },
									{ field: 'tradeSize', headerName: 'Trade Size', width: 120 },
									{
										field: 'pl',
										headerName: 'Projected PL',
										width: 120,
										valueGetter: (params) => {
											return `${(parseFloat(params.row.tradeValue)-parseFloat(params.row.priceOfButterfly)).toFixed(4)}`;
										},
									},
								]}
								initialState={{
									pagination: {
										paginationModel: { page: 0, pageSize: 10 },
									},
								}}
								pageSizeOptions={[5, 10]}
							/>
						</Grid> */}
						<Grid item md={12} pt={10}>
							<Typography>Order Book Download Time</Typography>
							<TableContainer component={Paper}>
								<Table aria-label="collapsible table">
									<TableHead>
										<TableRow>
											<TableCell align="right">Order Book</TableCell>
											<TableCell align="right">Last Book Time</TableCell>
											<TableCell align="right">Last Downloaded</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{schedulerReqData.map((market, index) => (
											<>
												<TableRow key={index} sx={{ '& > *': { borderBottom: 'unset' } }}>
													<TableCell component="th" scope="row">
														{selectedCurrency}-{market}
													</TableCell>
													<TableCell align="right">{lastOrderBookTime}</TableCell>
													<TableCell align="right">{lastBookDownloaded}</TableCell>
												</TableRow>
											</>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid> 
						<Grid item md={12}>
							<CSVLink data={exportUserLongTradeData} filename="BestOrderAtTheMoney.csv">
								<Button variant="contained">Export Best Order At The Money</Button>
							</CSVLink>
						</Grid>
						<Grid item md={12} pt={10}>
							<Typography>Summarize Best Order At The Money</Typography>
							<TableContainer component={Paper}>
								<Table aria-label="collapsible table">
									<TableHead>
										<TableRow>
											<TableCell>Trade Noted</TableCell>
											<TableCell align="right">Trade Size</TableCell>
											<TableCell align="right">ASK at time of trade notation</TableCell>
											<TableCell align="right">Value (Current BID)</TableCell>
											<TableCell align="right">Expected Value (%)</TableCell>
											{/* <TableCell align="right">Profitibility</TableCell>
											<TableCell align="right">Annualised</TableCell> */}
											<TableCell align="right">Buy</TableCell>
											<TableCell align="right">Sell (2)</TableCell>
											<TableCell align="right">Buy</TableCell>
											<TableCell align="right">Underlying Market Price at time of trade discovery</TableCell>
											<TableCell align="right">Current Profit (%)</TableCell>
											<TableCell align="right">Current Profit High Water Mark (%)</TableCell>
											<TableCell align="right">Projected APY Of Original Option Term (%)</TableCell>
											<TableCell align="right">{selectedCurrency} Projected P/L at Expiration</TableCell>
											{/* <TableCell align="right">Dollar Gain</TableCell> */}
											<TableCell align="right">Original Projected Option Term Profit Annualized (%)</TableCell>
											{/* <TableCell align="right">Top 24h Gain</TableCell> */}
											<TableCell align="right">Action</TableCell>
											<TableCell align="right">Market Data</TableCell>
											<TableCell align="right">Butterfly Data</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{userTrades.map((userTrade, index) => 
											{ 
												return (index == 0 ?
												<TableRow key={index} sx={{ '& > *': { borderBottom: 'unset' } }}>
													<TableCell component="th" scope="row">
														{new Date(userTrade.notedDate).toLocaleString()}
													</TableCell>
													
													<TableCell align="right">{userTrade.longTradeData.tradeSize}</TableCell>
													<TableCell align="right">{userTrade.longTradeData.longPrice}</TableCell>
													<TableCell align="right">{userTrade.longTradeData.currentBidPrice}</TableCell>
													<TableCell align="right">{parseFloat(userTrade.longTradeData.expected)*100}</TableCell>
													{/* <TableCell align="right">{parseFloat(userTrade.longTradeData.userTradeProfit).toFixed(2)} %</TableCell>
													<TableCell align="right">{parseFloat(userTrade.longTradeData.userAnnulisedTradeProfit).toFixed(2)} %</TableCell> */}
													<TableCell align="right">
														{userTrade.longTradeToExecute[0]?.security}
													</TableCell>
													<TableCell align="right">
														{userTrade.longTradeToExecute[1]?.security}
													</TableCell>
													<TableCell align="right">
														{userTrade.longTradeToExecute[2]?.security}
													</TableCell>
													<TableCell align="right">
														{userTrade.longTradeData.currentPrice}
													</TableCell>
													<TableCell align="right">
														{/* {((parseFloat(userTrade.longTradeData.currentBidPrice)/(parseFloat(userTrade.longTradeData.longPrice))-1)*100).toFixed(4)} */}
														{userTrade.longTradeData.currentProfit}
													</TableCell>
													<TableCell align="right">
														{userTrade.longTradeData.currentProfitHWM}
														{/* {userTrades.length > 0 ? ((parseFloat(userTrades[0].longTradeData.currentBidPrice)/(parseFloat(userTrades[0].longTradeData.longPrice))-1)*100).toFixed(4) : 0.00} */}
													</TableCell>
													<TableCell align="right">
														{parseFloat(userTrade.longTradeData.userTradeProfit).toFixed(2)} %
													</TableCell>
													<TableCell align="right">
														{userTrade.longTradeData.expectedProfit}
													</TableCell>
													{/* <TableCell align="right">
														{userTrade.longTradeData.receiveProfit.toFixed(2)}
													</TableCell> */}
													<TableCell align="right">
														{parseFloat(userTrade.longTradeData.userAnnulisedTradeProfit).toFixed(2)} %
													</TableCell>
													{/* <TableCell align="right">
														{userTrade.longTradeData.receiveProfit.toFixed(2)}
													</TableCell> */}
													<TableCell align="right">
														<Button size="small" onClick={() => {
															createLongDeribitOrders(userTrade.longTradeToExecute);
														}}>Hit Order</Button>
													</TableCell>
													<TableCell>
														<CSVLink 
															data={userTrade.marketData} 
															filename={`${userTrade.longTradeToExecute[0]?.security}_${userTrade.longTradeToExecute[1]?.security}_${userTrade.longTradeToExecute[2]?.security}_marketData.csv`}
														>
															<Button size="small">Export Market</Button>
														</CSVLink>
													</TableCell>
													<TableCell>
														<CSVLink 
															data={userTrade.butterflyData} 
															filename={`${userTrade.longTradeToExecute[0]?.security}_${userTrade.longTradeToExecute[1]?.security}_${userTrade.longTradeToExecute[2]?.security}_butterflyData.csv`}
														>
															<Button size="small">Export Butterfly</Button>
														</CSVLink>
													</TableCell>
												</TableRow>
												: (parseFloat(userTrade.longTradeToExecute[1]?.security.split("-")[2]) <= parseFloat(userTrade.longTradeData.currentPrice))) ? (
												<TableRow key={index} sx={{ '& > *': { borderBottom: 'unset' } }}>
													<TableCell component="th" scope="row">
														{new Date(userTrade.notedDate).toLocaleString()}
													</TableCell>
													
													<TableCell align="right">{userTrade.longTradeData.tradeSize}</TableCell>
													<TableCell align="right">{userTrade.longTradeData.longPrice}</TableCell>
													<TableCell align="right">{userTrade.longTradeData.currentBidPrice}</TableCell>
													<TableCell align="right">{parseFloat(userTrade.longTradeData.expected)*100}</TableCell>
													{/* <TableCell align="right">{parseFloat(userTrade.longTradeData.userTradeProfit).toFixed(2)} %</TableCell>
													<TableCell align="right">{parseFloat(userTrade.longTradeData.userAnnulisedTradeProfit).toFixed(2)} %</TableCell> */}
													<TableCell align="right">
														{userTrade.longTradeToExecute[0]?.security}
													</TableCell>
													<TableCell align="right">
														{userTrade.longTradeToExecute[1]?.security}
													</TableCell>
													<TableCell align="right">
														{userTrade.longTradeToExecute[2]?.security}
													</TableCell>
													<TableCell align="right">
														{userTrade.longTradeData.currentPrice}
													</TableCell>
													<TableCell align="right">
														{/* {((parseFloat(userTrade.longTradeData.currentBidPrice)/(parseFloat(userTrade.longTradeData.longPrice))-1)*100).toFixed(4)} */}
														{parseFloat(userTrade.longTradeData.currentProfit).toFixed(4)}%
													</TableCell>
													<TableCell align="right">
														{parseFloat(userTrade.longTradeData.currentProfitHWM).toFixed(4)}%
														{/* {
															(((parseFloat(userTrade.longTradeData.currentBidPrice)/(parseFloat(userTrade.longTradeData.longPrice))-1)*100).toFixed(4) < ((parseFloat(userTrade.longTradeData.currentAskBidPrice)/(parseFloat(userTrade.longTradeData.longPrice))-1)*100).toFixed(4)) ?
															((parseFloat(userTrade.longTradeData.currentAskBidPrice)/(parseFloat(userTrade.longTradeData.longPrice))-1)*100).toFixed(4) : ((parseFloat(userTrade.longTradeData.currentBidPrice)/(parseFloat(userTrade.longTradeData.longPrice))-1)*100).toFixed(4)
														} */}
														{/* {userTrades.length > 0 ? ((parseFloat(userTrades[0].longTradeData.currentBidPrice)/(parseFloat(userTrades[0].longTradeData.longPrice))-1)*100).toFixed(4) : 0.00} */}
													</TableCell>
													<TableCell align="right">
														{parseFloat(userTrade.longTradeData.userTradeProfit).toFixed(2)} %
													</TableCell>
													<TableCell align="right">
														{userTrade.longTradeData.expectedProfit}
													</TableCell>
													{/* <TableCell align="right">
														{userTrade.longTradeData.receiveProfit.toFixed(2)}
													</TableCell> */}
													<TableCell align="right">
														{parseFloat(userTrade.longTradeData.userAnnulisedTradeProfit).toFixed(2)} %
													</TableCell>
													{/* <TableCell align="right">
														{userTrade.longTradeData.receiveProfit.toFixed(2)}
													</TableCell> */}
													<TableCell align="right">
														<Button size="small" onClick={() => {
															createLongDeribitOrders(userTrade.longTradeToExecute);
														}}>Hit Order</Button>
													</TableCell>
													<TableCell>
														<CSVLink 
															data={userTrade.marketData} 
															filename={`${userTrade.longTradeToExecute[0]?.security}_${userTrade.longTradeToExecute[1]?.security}_${userTrade.longTradeToExecute[2]?.security}_marketData.csv`}
														>
															<Button size="small">Export Market</Button>
														</CSVLink>
													</TableCell>
													<TableCell>
														<CSVLink 
															data={userTrade.butterflyData} 
															filename={`${userTrade.longTradeToExecute[0]?.security}_${userTrade.longTradeToExecute[1]?.security}_${userTrade.longTradeToExecute[2]?.security}_butterflyData.csv`}
														>
															<Button size="small">Export Butterfly</Button>
														</CSVLink>
													</TableCell>
												</TableRow>
											) : undefined}
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
						<Grid item md={6}>
							<CSVLink data={exportUserTradeData} filename="OrderAtAnyStrikePrice.csv">
								<Button variant="contained">Export Order At Any Strike Price</Button>
							</CSVLink>
						</Grid>
						<Grid item md={12} pt={10}>
							<Typography>Best Order At Any Strike Price</Typography>
							<TableContainer component={Paper}>
								<Table aria-label="collapsible table">
									<TableHead>
										<TableRow>
											<TableCell>Trade Noted</TableCell>
											<TableCell align="right">Trade Size</TableCell>
											<TableCell align="right">ASK at time of trade notation</TableCell>
											<TableCell align="right">Value (Current BID)</TableCell>
											<TableCell align="right">Expected Value (%)</TableCell>
											{/* <TableCell align="right">Profitibility</TableCell>
											<TableCell align="right">Annualised</TableCell> */}
											<TableCell align="right">Buy</TableCell>
											<TableCell align="right">Sell (2)</TableCell>
											<TableCell align="right">Buy</TableCell>
											<TableCell align="right">Underlying Market Price at time of trade discovery</TableCell>
											<TableCell align="right">Current Profit (%)</TableCell>	
											<TableCell align="right">Current Profit High Water Mark (%)</TableCell>	
											<TableCell align="right">Projected APY Of Original Option Term (%)</TableCell>
											<TableCell align="right">{selectedCurrency} Projected P/L at Expiration</TableCell>
											{/* <TableCell align="right">Dollar Gain</TableCell> */}
											<TableCell align="right">Original Projected Option Term Profit Annualized (%)</TableCell>
											{/* <TableCell align="right">Top 24h Gain</TableCell> */}
											<TableCell align="right">Action</TableCell>
											<TableCell align="right">Market Data</TableCell>
											<TableCell align="right">Butterfly Data</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{userTrades.map((userTrade, index) => (
											<>
												<TableRow key={index} sx={{ '& > *': { borderBottom: 'unset' } }}>
													<TableCell component="th" scope="row">
														{new Date(userTrade.notedDate).toLocaleString()}
													</TableCell>
													
													<TableCell align="right">{userTrade.tradeSize}</TableCell>
													<TableCell align="right">{userTrade.longPrice}</TableCell>
													<TableCell align="right">{userTrade.currentBidPrice}</TableCell>
													<TableCell align="right">{parseFloat(userTrade.expected)*100}</TableCell>
													{/* <TableCell align="right">{parseFloat(userTrade.userTradeProfit).toFixed(2)} %</TableCell>
													<TableCell align="right">{parseFloat(userTrade.userAnnulisedTradeProfit).toFixed(2)} %</TableCell> */}
													<TableCell align="right">
														{userTrade.tradeToExecute[0]?.security}
													</TableCell>
													<TableCell align="right">
														{userTrade.tradeToExecute[1]?.security}
													</TableCell>
													<TableCell align="right">
														{userTrade.tradeToExecute[2]?.security}
													</TableCell>
													<TableCell align="right">
														{userTrade.currentPrice}
													</TableCell>
													{/* <TableCell align="right">
														{parseFloat(userTrade.tradeCost) - (parseFloat(userTrade.expected)*100)}
													</TableCell> */}
													<TableCell align="right">
														{parseFloat(userTrade.currentProfit).toFixed(4)}%
														{/* {((parseFloat(userTrade.currentBidPrice)/(parseFloat(userTrade.longPrice))-1)*100).toFixed(4)} */}
													</TableCell>
													<TableCell align="right">
														{parseFloat(userTrade.currentProfitHWM).toFixed(4)}%
														{/* {
															(((parseFloat(userTrade.currentBidPrice)/(parseFloat(userTrade.longPrice))-1)*100) > ((parseFloat(userTrade.currentAskBidPrice)/(parseFloat(userTrade.longPrice))-1)*100)) ?
															((parseFloat(userTrade.currentBidPrice)/(parseFloat(userTrade.longPrice))-1)*100).toFixed(4) : ((parseFloat(userTrade.currentAskBidPrice)/(parseFloat(userTrade.longPrice))-1)*100).toFixed(4)
														} */}
														{/* {userTrades.length > 0 ? ((parseFloat(userTrades[0].currentBidPrice)/(parseFloat(userTrades[0].longPrice))-1)*100).toFixed(4) : 0.00} */}
													</TableCell>
													<TableCell align="right">
														{parseFloat(userTrade.userTradeProfit).toFixed(2)} %
													</TableCell>
													<TableCell align="right">
														{userTrade.expectedProfit}
													</TableCell>
													{/* <TableCell align="right">
														{userTrade.receiveProfit.toFixed(2)}
													</TableCell> */}
													<TableCell align="right">
														{parseFloat(userTrade.userAnnulisedTradeProfit).toFixed(2)} %
													</TableCell>
													{/* <TableCell align="right">
														{userTrade.receiveProfit.toFixed(2)}
													</TableCell> */}
													<TableCell>
														<Button size="small" onClick={() => {
															createDeribitOrders(userTrade.tradeToExecute);
														}}>Hit Order</Button>
													</TableCell>
													<TableCell>
														<CSVLink 
															data={userTrade.marketData} 
															filename={`${userTrade.tradeToExecute[0]?.security}_${userTrade.tradeToExecute[1]?.security}_${userTrade.tradeToExecute[2]?.security}_marketData.csv`}
														>
															<Button size="small">Export Market</Button>
														</CSVLink>
													</TableCell>
													<TableCell>
														<CSVLink 
															data={userTrade.butterflyData} 
															filename={`${userTrade.tradeToExecute[0]?.security}_${userTrade.tradeToExecute[1]?.security}_${userTrade.tradeToExecute[2]?.security}_butterflyData.csv`}
														>
															<Button size="small">Export Butterfly</Button>
														</CSVLink>
													</TableCell>
												</TableRow>
											</>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
						{/* <Grid item md={12} pt={10}>
							<Typography>Best Order</Typography>
							<TableContainer component={Paper}>
								<Table aria-label="collapsible table">
									<TableHead>
										<TableRow>
											<TableCell>Trade Cost</TableCell>
											<TableCell align="right">Trade Size</TableCell>
											<TableCell align="right">Trade Profit</TableCell>
											<TableCell align="right">Annualised</TableCell>
											<TableCell align="right"></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{
											(userTrades[0]) ? (
												<>
													<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
														<TableCell component="th" scope="row">
															{userTrades[0].longTradeData.tradeCost}
														</TableCell>
														<TableCell align="right">{userTrades[0].longTradeData.tradeSize}</TableCell>
														<TableCell align="right">{parseFloat(userTrades[0].longTradeData.userTradeProfit).toFixed(2)} %</TableCell>
														<TableCell align="right">{parseFloat(userTrades[0].longTradeData.userAnnulisedTradeProfit).toFixed(2)} %</TableCell>
														<TableCell align="right">
															<Button variant="contained" onClick={createLongDeribitOrders}>Hit Order</Button>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
														<Collapse in={true} timeout="auto" unmountOnExit>
															<Box sx={{ margin: 1 }}>
																<Table size="small" aria-label="purchases">
																	<TableHead>
																	<TableRow>
																		<TableCell>Security</TableCell>
																		<TableCell>Amount</TableCell>
																		<TableCell align="right">Price</TableCell>
																		<TableCell align="right">Total</TableCell>
																	</TableRow>
																	</TableHead>
																	<TableBody>
																	{userTrades[0].longTradeToExecute.map((trade, index) => (
																		<TableRow key={index}>
																			<TableCell component="th" scope="row">
																				{trade.security}
																			</TableCell>
																			<TableCell>{trade.amount}</TableCell>
																			<TableCell align="right">{trade.price}</TableCell>
																			<TableCell align="right">{trade.total}</TableCell>
																		</TableRow>
																	))}
																	</TableBody>
																</Table>
															</Box>
														</Collapse>
														</TableCell>
													</TableRow>
												</>
											) : (<></>)											
										}
									
									</TableBody>
								</Table>
								</TableContainer>
						</Grid> */}

						{/* <Grid item md={12} pt={10}>
							{
								userTrades.map((userTrade, index) => 
									<><Card variant="outlined" key={index}>
										<CardContent>
											<Grid container pl={5} spacing={5}>
											<Grid md={6} pt={6}>
													<p>
														* <span>Limit as % of underlying: </span> {userTrade.limitOfUnderline}
													</p>
													<p>
														* <span>Capital limit on single trade collateral: </span> {userTrade.capitalLimitOnSingleTrade}
													</p>
													<p>
														* <span>Trade profit: </span> {parseFloat(userTrade.userTradeProfit).toFixed(2)} %
													</p>
													<p>
														* <span>Annualised Return: </span> {parseFloat(userTrade.userAnnulisedTradeProfit).toFixed(2)} %
													</p>
													<p>
														* <span>Days in trade: </span> {userTrade.days}
													</p>
													


													<p>
														* <span>Current value of underlying: </span> {userTrade.currentPrice}
													</p>
													<p>
														* <span>Collateral reqiurement in USD (per contract): </span> {userTrade.collateralInDollar}
													</p>
													<p>
														* <span>Expected profit as %: </span> {userTrade.expectedProfit}
													</p> 
												</Grid>
												<Grid md={6} pt={5}>
													<p>
														* <span>Trade cost: </span> {parseFloat(userTrade.tradeCost).toFixed(2)} %
													</p>
													<p>
														* <span>Trade Size: </span> {userTrade.tradeSize}
													</p>
													<p>
														* <span>Lesser of trade size and capital risk limit: </span> {userTrade.maxTradeSize}
													</p>
													<p>
														* <span>Price: </span> {userTrade.price}
													</p>
													<p>
														* <span>Price in dollars: </span> ${parseFloat(userTrade.priceInDollars).toFixed(2)}
													</p>	


													<p>
														* <span>Settlement Commission: </span> {userSettlementShowCommission}
													</p>
													<p>
														* <span>Trade Type: </span> {userTrade.tradeType}
													</p>
													<p>
														* <span>Trade Size: </span> {userTrade.tradeSize}
													</p>
													<p>
														* <span>Lesser of trade size and capital risk limit: </span> {userTrade.maxTradeSize}
													</p>
													<p>
														* <span>Price: </span> {userTrade.price}
													</p>
													<p>
														* <span>Price in dollars: </span> {userTrade.priceInDollars}
													</p>
													<p>
														* <span>Cost of trade: </span> {userTrade.costOfTrade}
													</p>
													<p>
														* <span>You should receive in profit : </span> {userTrade.receiveProfit}
													</p>
													<p>
														* <span>You should receive in total: </span> {userTrade.totalReceiveProfit}
													</p> 
												</Grid>
												<Grid item md={12}>
													<p>* Trade Type: Long PUT Butterfly</p>
													<TableContainer component={Paper}>
														<Table sx={{ minWidth: 650 }} aria-label="simple table">
															<TableHead>
															<TableRow>
																<TableCell>Side</TableCell>
																<TableCell align="right">Security</TableCell>
																<TableCell align="right">Amount</TableCell>
																<TableCell align="right">Price</TableCell>
																<TableCell align="right">Total</TableCell>
															</TableRow>
															</TableHead>
															<TableBody>
																<TableRow
																	sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
																>
																	<TableCell component="th" scope="row">
																		{userTrade.tradeToExecute[0].side}
																	</TableCell>
																	<TableCell align="right">{userTrade.tradeToExecute[0].security}</TableCell>
																	<TableCell align="right">{userTrade.tradeToExecute[0].amount}</TableCell>
																	<TableCell align="right">{userTrade.tradeToExecute[0].price}</TableCell>
																	<TableCell align="right">{userTrade.tradeToExecute[0].total}</TableCell>
																</TableRow>
															</TableBody>
														</Table>
														</TableContainer>
												</Grid>
												<Grid item md={12}>
														<Button variant="contained" onClick={createDeribitOrders}>Hit Deribit Orders</Button>
												</Grid>
												<Grid md={6} pt={6}>
													<p>
														* <span>Limit as % of underlying: </span> {userTrade.longTradeData.limitOfUnderline}
													</p>
													<p>
														* <span>Capital limit on single trade collateral: </span> {userTrade.longTradeData.capitalLimitOnSingleTrade}
													</p>
													<p>
														* <span>Trade profit: </span> {parseFloat(userTrade.longTradeData.userTradeProfit).toFixed(2)} %
													</p>
													<p>
														* <span>Annualised Return: </span> {parseFloat(userTrade.longTradeData.userAnnulisedTradeProfit).toFixed(2)} %
													</p>
													<p>
														* <span>Days in trade: </span> {userTrade.longTradeData.days}
													</p> 
													


													<p>
														* <span>Current value of underlying: </span> {userTrade.currentPrice}
													</p>
													<p>
														* <span>Collateral reqiurement in USD (per contract): </span> {userTrade.collateralInDollar}
													</p>
													<p>
														* <span>Expected profit as %: </span> {userTrade.expectedProfit}
													</p>
												</Grid>
												<Grid md={6} pt={5}>
													<p>
														* <span>Trade cost: </span> {parseFloat(userTrade.longTradeData.tradeCost).toFixed(2)} %
													</p>
													<p>
														* <span>Trade Size: </span> {userTrade.longTradeData.tradeSize}
													</p>
													<p>
														* <span>Lesser of trade size and capital risk limit: </span> {userTrade.longTradeData.maxTradeSize}
													</p>
													<p>
														* <span>Price: </span> {userTrade.longTradeData.price}
													</p>
													<p>
														* <span>Price in dollars: </span> ${parseFloat(userTrade.longTradeData.priceInDollars).toFixed(2)}
													</p>
													 <p>
														* <span>Settlement Commission: </span> {userSettlementShowCommission}
													</p>
													<p>
														* <span>Trade Type: </span> {userTrade.tradeType}
													</p>
													<p>
														* <span>Trade Size: </span> {userTrade.tradeSize}
													</p>
													<p>
														* <span>Lesser of trade size and capital risk limit: </span> {userTrade.maxTradeSize}
													</p>
													<p>
														* <span>Price: </span> {userTrade.price}
													</p>
													<p>
														* <span>Price in dollars: </span> {userTrade.priceInDollars}
													</p>
													<p>
														* <span>Cost of trade: </span> {userTrade.costOfTrade}
													</p>
													<p>
														* <span>You should receive in profit : </span> {userTrade.receiveProfit}
													</p>
													<p>
														* <span>You should receive in total: </span> {userTrade.totalReceiveProfit}
													</p> 
												 </Grid> 
												<Grid item md={12}>
													<p>* Trade Type: Long Current Strike</p>
													<TableContainer component={Paper}>
														<Table sx={{ minWidth: 650 }} aria-label="simple table">
															<TableHead>
															<TableRow>
																<TableCell>Side</TableCell>
																<TableCell align="right">Security</TableCell>
																<TableCell align="right">Amount</TableCell>
																<TableCell align="right">Price</TableCell>
																<TableCell align="right">Total</TableCell>
															</TableRow>
															</TableHead>
															<TableBody>
																{
																	userTrade.longTradeToExecute.map((trade, index) => <TableRow
																		key={index}
																		sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
																	>
																		<TableCell component="th" scope="row">
																			{trade.side}
																		</TableCell>
																		<TableCell align="right">{trade.security}</TableCell>
																		<TableCell align="right">{trade.amount}</TableCell>
																		<TableCell align="right">{trade.price}</TableCell>
																		<TableCell align="right">{trade.total}</TableCell>
																	</TableRow>)
																}
																
															</TableBody>
														</Table>
														</TableContainer>
												</Grid> 
												<Grid item md={12}>
														<Button variant="contained" onClick={createLongDeribitOrders}>Hit Deribit Long Current Strike Orders</Button>
												</Grid> 
											</Grid>
										</CardContent>
									</Card>
									<br/>
									</>
								)
							}
						</Grid> */}
{/* 
						<Grid item md={12} pt={10}>
							{(butterflyProcess || marketDataProcess) ? 
								<LinearProgress />
							: 
							<div></div>
							}
							<table style={{width: '100%'}}>
								<thead>
									<tr>
										<th>Long Low Strike Call</th>
										<th>Short Middle Strike Call</th>
										<th>Long High Strike Call</th>
										<th>Expiry</th>
										<th>Low Strike</th>
										<th>Middle Strike</th>
										<th>High Strike</th>
										<th>Days Expiry</th>
										<th>Average Volatility</th>
										<th>% Price of Butterfly</th>
										<th>% Trade Value</th>
										<th>Normal Commission</th>
										<th>Expiry Commission</th>
										<th>Projected PL</th>
									</tr>
								</thead>
								<tbody>
									{
										butterflyData.map((butterfly, index) => 
											<tr key={index}>
												<td>{butterfly.lowStrikeMarket}</td>
												<td>{butterfly.middleStrikeMarket}</td>
												<td>{butterfly.highStrikeMarket}</td>
												<td>{butterfly.expiry}</td>
												<td>{butterfly.lowStrike}</td>
												<td>{butterfly.middleStrike}</td>
												<td>{butterfly.highStrike}</td>
												<td>{butterfly.dateOfExpiry}</td>
												<td>{butterfly.averageVolatility}</td>
												<td>{butterfly.priceOfButterfly}</td>
												<td>{butterfly.tradeValue}</td>
												<td>{butterfly.commission}</td>
												<td>{butterfly.settlement_commission}</td>
												<td>{(parseFloat(butterfly.tradeValue)-parseFloat(butterfly.priceOfButterfly)).toFixed(4)}</td>
											</tr>
										)
									}
								</tbody>
							</table>
						</Grid> */}
					</Grid>
			</Container>

		</div>
	);
};

const mapStateToProps = (state) => ({
	deribit: state.deribit,
});

export default connect(mapStateToProps, {
	getOptions,
	getAvailableDates,
	startConstruction,
	resetTrades,
	createDeribitCombo,
})(Home);
