import React from "react";
import { ToastContainer } from "react-toastify";
import { Routes, Route, BrowserRouter } from "react-router-dom";

//public routes
import Landing from "./pages/index";

//// css
import "react-toastify/dist/ReactToastify.css";
import "./styles/global.css";

const App = () => {
	return (
		<React.Fragment>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<BrowserRouter>
				<Routes>
					{/* public routess */}
					<Route path={"/"} element={<Landing />} />
						
				</Routes>
			</BrowserRouter>
		</React.Fragment>
	);
};

export default App;
