const initialState = {
	errors: {},
	isLoading: false,
	response: {},
	notifications: {},
	availableOptions: [],
	availableDates: [],
	butterflyDatas: {},
    optionTypes: ['call', 'put'],
};

const deribit = (state = initialState, action) => {
	const actionType = {

		GET_AVAILABLE_OPTIONS: {
			...state,
			isLoading: false,
			availableOptions: action.payload?.data?? [],
			errors: {},
		},

		GET_DATE_OPTIONS: {
			...state,
			isLoading: false,
			availableDates: action.payload?.data?? [],
			errors: {},
		},

		START_CONSTRUCTION: {
			...state,
			isLoading: false,
			butterflyDatas: action.payload?.data?? [],
			errors: {},
		},

		CREATE_DERIBIT_COMBO: {
			...state,
			isLoading: false,
			errors: {},
		},

		RESET_TRADES: {
			...state,
			isLoading: false,
			errors: {},
		},

		CLEAR_RESPONSE: {
			...state,
			response: {},
		},

		CLEAR_NOTIFICATION: {
			...state,
			notifications: {},
		},
		DEFAULT: { ...state },
	};
	return actionType[action.type] !== undefined
		? actionType[action.type]
		: actionType["DEFAULT"];
};

export default deribit;
