export const GET_AVAILABLE_OPTIONS = "GET_AVAILABLE_OPTIONS";
export const GET_AVAILABLE_OPTIONS_FAIL = "GET_AVAILABLE_OPTIONS_FAIL";

export const GET_DATE_OPTIONS = "GET_DATE_OPTIONS";
export const GET_DATE_OPTIONS_FAIL = "GET_DATE_OPTIONS_FAIL";

export const START_CONSTRUCTION = "START_CONSTRUCTION";
export const START_CONSTRUCTION_FAIL = "START_CONSTRUCTION_FAIL";
export const RESET_TRADES = "RESET_TRADES";

export const CREATE_DERIBIT_COMBO = "CREATE_DERIBIT_COMBO";