import { 
	GET_AVAILABLE_OPTIONS, 
	GET_DATE_OPTIONS, 
	START_CONSTRUCTION, 
	RESET_TRADES,
	CREATE_DERIBIT_COMBO,
} from "./actionTypes";

export const getOptions = (currency, type, option_type, day, side, userCommission,
	userSettlementCommission, userCapitalLimit, atmMaxCost,
	atmMinValue,
	atPriceMaxCost,
	atPriceMinValue, rVolatility, endTime) => {
	return {
		type: GET_AVAILABLE_OPTIONS,
		payload: { currency, type, option_type, day, side, userCommission,
			userSettlementCommission, userCapitalLimit, atmMaxCost,
			atmMinValue,
			atPriceMaxCost,
			atPriceMinValue, rVolatility, endTime },
	};
};

export const getAvailableDates = (currency, type, option_type) => {
	return {
		type: GET_DATE_OPTIONS,
		payload: { currency, type, option_type },
	};
};

export const startConstruction = () => {
	return {
		type: START_CONSTRUCTION,
		payload: {},
	};
};

export const createDeribitCombo = (trades) => {
	return {
		type: CREATE_DERIBIT_COMBO,
		payload: {
			trades: trades
		},
	};
};

export const resetTrades = () => {
	return {
		type: RESET_TRADES,
		payload: {}
	}
}
