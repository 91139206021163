import { all, fork } from "redux-saga/effects";

//public
import DeribitSaga from "./deribit/saga";

export default function* rootSaga() {
	yield all([
		fork(DeribitSaga),
	]);
}
